<template>
  <notification-item match-satrt-item :message="message" @delete-item="deleteItem" @close="close">
    <template v-slot:title>
      <span>{{ $t('_.title') }}</span>
    </template>
    <p class="main-msg">{{ $t(`_.statusMessage.${isLeader ? 'leader' : 'member'}`, { title }) }}</p>
    <ul v-if="isTeamInfoAvail" class="verse-con">
      <li>
        <div class="team-image">
          <CdnImg :src="home.image" :fallback="defaultTeamProfile" />
        </div>
        <span>{{ home.name }}</span>
      </li>
      <li>VS</li>
      <li>
        <div class="team-image">
          <CdnImg :src="away.image" :fallback="defaultTeamProfile" />
        </div>
        <span>{{ away.name }}</span>
      </li>
    </ul>
    <CountDown v-if="waitingEnd" :time="waitingEnd" :expired-desc="$t('shared.notification.checkInExpired')" />
    <template v-slot:buttons>
      <button v-if="!isPassed" @click="goLobby">{{ $t(`_.${realTime ? 'realTimeButton' : 'button'}`) }}</button>
    </template>
  </notification-item>
</template>

<script>
import Specific from '@shared/types/Specific';
import CdnImg from '@shared/components/common/CdnImg.vue';
import CountDown from '@shared/components/common/CountDown.vue';
import { isPassed } from '@shared/utils/timeUtils';
import { NOTIFICATION_ROOM_TYPE_GAMES } from '@shared/data/constants/notification';
import NotificationItem from '@shared/components/notification/NotificationItem.vue';

export default {
  name: 'MatchStartItem',
  components: { CountDown, CdnImg, NotificationItem },
  lexicon: 'shared.notification.MATCH_START',
  props: {
    message: Specific,
  },
  data() {
    return {
      defaultTeamProfile: '/shared/img/notification/default_team.png',
    };
  },
  computed: {
    /** @type {MatchInfo | {}} */
    matchData() {
      return this.message.data.match || {};
    },
    application() {
      return this.message?.data?.application || {};
    },
    tournament() {
      return this.message?.data?.tournament || {};
    },
    home() {
      return this.matchData.home?.team || {};
    },
    away() {
      return this.matchData.away?.team || {};
    },
    room() {
      return this.matchData?.room || {};
    },
    isLeader() {
      const leaderIds = this.application.leaderIds || [];
      const userId = this.$store.state.auth.userId || '';
      return leaderIds.includes(userId);
    },
    isRoomType() {
      return NOTIFICATION_ROOM_TYPE_GAMES.indexOf(this.tournament.gameId) > -1;
    },
    isTeamInfoAvail() {
      const home = this.home || {};
      const away = this.away || {};
      const isExistTeam = Boolean(away.name && home.name);
      return !NOTIFICATION_ROOM_TYPE_GAMES.includes(this.tournament.gameId) && isExistTeam;
    },
    isPassed() {
      return this.waitingEnd ? isPassed(this.$store, this.waitingEnd) : true;
    },
    waitingEnd() {
      const { room } = this;
      const match = this.matchData;
      const arena = this.tournament;
      return NOTIFICATION_ROOM_TYPE_GAMES.includes(arena.gameId) ? room.waitingEnd : match.waitingEnd;
    },
    title() {
      return this.tournament.title || this.$t('anonymous.arena');
    },
    realTime() {
      return Boolean(this.message?.realTime);
    },
  },
  methods: {
    goLobby() {
      const { tournamentId, gameId, isWcgArena } = this.tournament;
      const { stageId } = this.matchData;
      const { matchId } = this.matchData;

      this.routeMainPath({ path: `${this.$i18n('path')}/arena/${isWcgArena ? 'wcg/' : ''}${tournamentId || gameId}/bracket?checkin=true&openMatch=${matchId}&view=lobby&stage-id=${stageId}&round=all` });
      this.$emit('read-item', this.message || {});
      this.close();
    },
    deleteItem(notificationId) {
      this.$emit('delete-item', notificationId);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[match-satrt-item] {
  .verse-con { .m(15, 0); .p(4); .bgc(#2c2c2c); .br(10); .crop;
    > li { .rel; .flex; align-items: center; justify-content: center; .p(0, 4); .bgc(rgba(255, 255, 255, 0.1));
      .team-image { .wh(32, 32); .mr(8); .p(4, 0); .vam;
        > img { .wh(100%, 100%); .br(8); }
      }
      > span { .fs(13, 19); .c(#fff); .ellipsis(1); }

      &:nth-of-type(1) { border-radius: 10px 10px 4px 4px; }
      &:nth-of-type(2) { .p(9, 0); .fs(13); .medium; .bgc(transparent); }
      &:nth-of-type(3) { border-radius: 4px 4px 10px 10px; }
    }
  }
}

</style>
